<template>
  <o-container ref="container">
    <v-card elevation="2" shaped class=" fill-height">
      <v-card-title v-if="action === ''" class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto ">
          {{ title }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="green"
            icon="mdi-plus"
            type="label-icon"
            label="Add New"
            @on-click="add()"
          />

          <o-button
            :busy="busy"
            color="warning"
            icon="mdi-filter"
            type="label-icon"
            label="Filter"
            @on-click="showFilter = true"            
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-sync"
            type="label-icon"
            label="Refresh"
            @on-click="reset()"
          />

          <o-drawer
            v-if="!busy"
            :auto-close="true"
            :actions="[
              {
                key: 'on-item-reset',
                label: 'RESET',
                icon: 'mdi-refresh',
                color: 'warning',
              },
              {
                key: 'on-item-search',
                label: 'SEARCH',
                icon: 'mdi-text-box-search-outline',
                color: 'success',
              },
            ]"
            button-icon="mdi-filter-plus-outline"
            :title="`FILTERS`"
            :use-button="false"
            width="50vw"
            :visible="showFilter"
            @on-item-search="
              showFilter = false;
              reset();
            "
            @on-item-reset="
              showFilter = false;
              reset(true);
            "
            @on-close="showFilter = false"
          >
            <v-row class="pa-0 ma-1">
              <v-col cols="12" class="pa-1 ma-0">
                <o-combo-box
                  v-model="parameters.account_id"
                  :busy="busy"
                  label="Account"
                  :items="accounts"
                  :multiple="true" 
                />
              </v-col>
              <v-col cols="12" class="pa-1 ma-0">
                <o-input
                  v-model="parameters.email"
                  :busy="busy"
                  label="E-mail"
                  :hasClear="true"
                />
              </v-col>
              <v-col cols="6" class="pa-1 ma-0">
                <o-input
                  v-model="parameters.first_name"
                  :busy="busy"
                  label="First Name"
                  :hasClear="true"
                />
              </v-col>
              <v-col cols="6" class="pa-1 ma-0">
                <o-input
                  v-model="parameters.last_name"
                  :busy="busy"
                  label="Last Name"
                  :hasClear="true"
                />
              </v-col>
              <v-col cols="12" class="pa-1 ma-0">
                <o-combo-box
                  v-model="parameters.role_id"
                  :busy="busy"
                  label="Roles"
                  :items="roles"
                  :multiple="true"
                />
              </v-col>
              <v-col cols="4" class="pa-1 ma-0">
                <o-combo-box
                  v-model="parameters.active"
                  :busy="busy"
                  label="Active"
                  :items="booleans"
                  :hasId="false"
                  :multiple="true"
                />
              </v-col>
              <v-col cols="4" class="pa-1 ma-0">
                <o-combo-box
                  v-model="parameters.super_user"
                  :busy="busy"
                  label="Super User"
                  :items="booleans"
                  :hasId="false"
                  :multiple="true"
                />
              </v-col>
              <v-col cols="4" class="pa-1 ma-0">
                <o-combo-box
                  v-model="parameters.multi_account"
                  :busy="busy"
                  label="Multi Account"
                  :items="booleans"
                  :hasId="false"
                  :multiple="true"
                />
              </v-col>
            </v-row>
          </o-drawer>
        </div>        
      </v-card-title>
      <v-divider v-if="action === ''" />

      <v-card-text v-if="action === ''" class="text-primary">
        <o-table
          ref="table"
          type="rest"
          :lazy="true"
          :busy="busy"
          :has-paging="true"
          :height="getHeight(442)"
          :fixed-header="true"
          method="POST"
          :rest="{
            url: `/rest/intranet/list`,
            payload: listParameters,
          }"
          :headers="[
            {
              text: '#',
              align: 'end',
              sortable: false,
              value: 'row_no',
              type: 'int',
            },
            {
              text: 'Account',
              align: 'start',
              sortable: false,
              value: 'account_name',
              type: 'slot',
            },
            {
              text: 'Name',
              align: 'start',
              sortable: false,
              value: 'first_name',
              type: 'slot',
            },
            {
              text: 'Email',
              align: 'start',
              sortable: false,
              value: 'email',
              type: 'text',
            },
            {
              text: 'Role',
              align: 'start',
              sortable: false,
              value: 'role_name',
              type: 'slot',
            },
            {
              text: 'Active',
              align: 'center',
              sortable: false,
              value: 'active',
              type: 'boolean',
            },
            {
              text: 'Super',
              align: 'center',
              sortable: false,
              value: 'super_user',
              type: 'boolean',
            },
            {
              text: 'Multi',
              align: 'center',
              sortable: false,
              value: 'multi_account',
              type: 'boolean',
            },
            {
              text: 'Created Date',
              align: 'start',
              value: 'created_at',
              type: 'date-time',
            },
            {
              value: 'action',
              type: 'action',
              sortable: false,
              actions: [
                {
                  key: 'on-item-edit',
                  label: 'Edit',
                  icon: 'mdi-pencil-box-outline',
                  color: 'success',
                },
                {
                  key: 'on-item-delete',
                  label: 'Delete',
                  icon: 'mdi-trash-can-outline',
                  color: 'danger',
                },
              ],
            },
            {
              text: 'Deleted Date',
              align: 'start',
              value: 'deleted_at',
              type: 'date-time',
            },
            {
              text: 'Deleted By',
              align: 'start',
              value: 'deleted_by_name',
              type: 'slot',
            },
          ]"
          @set-busy="setBusy"
          @on-item-edit="edit"
          @on-item-delete="remove"
        >
          <template v-slot:slot="row">
            <div
              v-if="row.prop.key === 'account_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.account_id > 0">
                {{ row.prop.item.account_name }} ({{
                  row.prop.item.account_id
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === 'first_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              {{ row.prop.item.last_name }}, {{ row.prop.item.first_name }} ({{
                row.prop.item.id
              }})
            </div>
            <div
              v-if="row.prop.key === 'role_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.role_id > 0">
                {{ row.prop.item.role_name }} ({{ row.prop.item.role_id }})
              </template>
            </div>
            <div
              v-if="row.prop.key === 'created_by_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.created_by > 0">
                {{ row.prop.item.created_by_name }} ({{
                  row.prop.item.created_by
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === 'deleted_by_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.deleted_by > 0">
                {{ row.prop.item.deleted_by_name }} ({{
                  row.prop.item.deleted_by
                }})
              </template>
            </div>
          </template>
        </o-table>
      </v-card-text>

      <v-card-title v-if="action !== ''" class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto">
          {{ `${action} - ${title}` }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="green"
            icon="mdi-content-save"
            type="label-icon"
            label="Save"
            @on-click="save()"
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-keyboard-backspace"
            type="label-icon"
            label="Back"
            @on-click="action = ''"
          />
        </div>
      </v-card-title>

      <v-divider v-if="action !== ''" />

      <div v-if="action !== ''" class="ml-5 pb-5 mr-5">
        <v-row class="o-container-content">
          <v-col cols="12" class="pa-10 pr-15">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" class="ma-0 pa-0">
                  <h5><small>Information</small></h5>
                </v-col>
                <v-col cols="6">
                  <o-input
                    v-model="model.first_name"
                    :busy="busy"
                    label="First Name"
                    :is-required="true"
                    :rules="[(v) => !!v || 'This is required']"
                  />
                </v-col>
                <v-col cols="6">
                  <o-input
                    v-model="model.last_name"
                    :busy="busy"
                    label="Last Name"
                    :is-required="true"
                    :rules="[(v) => !!v || 'This is required']"
                  />
                </v-col>
               
                <v-col cols="6">
                  <o-combo-box
                    v-model="model.account_id"
                    :busy="true"
                    label="Account"
                    :items="accounts"
                  />
                </v-col>
                <v-col cols="6">
                  <o-combo-box
                    v-model="model.role_id"
                    :busy="busy"
                    label="Role"
                    :items="roles"
                  />
                </v-col>
                
                <v-col cols="12" class="ma-0 pa-0">
                  <h5><small>Credentials</small></h5>
                </v-col>
                 <v-col cols="12">
                  <o-input
                    v-model="model.email"
                    :busy="busy"
                    label="E-mail"
                    :is-required="true"
                    type="email"
                  />
                </v-col>
                <v-col cols="6">
                  <o-input
                    v-model="model.password"
                    :busy="busy"
                    label="Password"
                    :is-required="true"
                  />
                </v-col>
                <v-col cols="6">
                  <o-input
                    v-model="model.confirm_password"
                    :busy="busy"
                    label="Confirm Password"
                    :is-required="true"
                  />
                </v-col>
                <v-col cols="12">
                  <o-check-box
                    v-model="model.active"
                    :busy="busy"
                    label="Active"
                    style="display:inline-block"
                  />

                  <o-check-box
                    v-model="model.super_user"
                    :busy="busy"
                    label="Super User"
                    style="display:inline-block"
                  />

                  <o-check-box
                    v-model="model.multi_account"
                    :busy="busy"
                    label="Multi Account"
                    style="display:inline-block"
                  />
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <h5><small>Preferences</small></h5>
                </v-col>

                <v-col cols="12" class="mt-0 pt-0">
                  <o-input
                    v-model="model.ui_preferences.page_size"
                    :busy="busy"
                    label="Page Size"
                    :is-required="true"
                  />
                </v-col>
                <v-col cols="12" class="mt-0 pt-0">
                  <o-combo-box
                    v-model="model.ui_preferences.account_ids"
                    :busy="false"
                    label="Global Account"
                    :items="accounts"
                    :multiple="true"
                    @on-change="getAdvertiserByAccount"
                  />
                </v-col> 
                <v-col cols="12" class="ma-0 pa-0" v-if="model.ui_preferences.account_ids.length > 0">
                  <h5><small>Advertisers</small></h5>
                </v-col>
                <template v-for="(acct, i) in accounts">
                <v-col cols="12" class="mt-0 pt-0" v-if="model.ui_preferences.account_ids.includes(acct.id) " v-bind:key="i">
                    <o-combo-box
                      v-model="model.ui_preferences.advertiser_ids[acct.id.toString()]"
                      :busy="false"
                      :label="acct.name"
                      :items="advertisers[acct.id.toString()]"
                      :multiple="true"
                    />
                  </v-col>
                </template>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </o-container>
</template>

<script>
import RestApi from "@/services/RestApi";
import { mapGetters } from "vuex";
import md5  from "md5";

export default {
  name: "ManageUser",
  mixins: [RestApi],
  props: {
    dialogName: {
      type: String,
      default: "",
    },
  },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Users",
  },
  data() {
    return {
      title: "Users",
      busy: false,
      action: "", 
      parameters: {
        email: "", 
        first_name: "",
        last_name: "",
        role_id: [],
        super_user: [],
        multi_account: [],
        active: [],
        account_id: [],
        user_id: [],
      },
      accounts: [],
      roles: [],
      users: [],
      valid: false,
      baseModel: {
        id: 0,
        super_user: false,
        multi_account: false,
        email: "",
        login_token: {},
        first_name: "",
        last_name: "",
        role_id: 0,
        role_name: "",
        password: "",
        confirm_password: "",
        ui_preferences: { page_size: 100, advertiser_ids: [], manufacturer_ids: [], agency_ids: [], account_ids: [] },
        last_login: null,
        account_id: 0,
        active: false,
        created_at: null,
        updated_at: null,
        user_id: 0,
        alt_account_id: 0,
        updated_by: 0,
      },
      originalModel: {},
      model: {},
      advertisers: [],
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
  },
  created() {
    this.listParameters.fields = [
      "ROW_NUMBER() OVER(ORDER BY a.deleted_at DESC, a.updated_at DESC) row_no",
      "a.*",
      "b.account_name",
      "c.name as role_name",
      "concat(d.last_name, ', ', d.first_name) as deleted_by_name",
    ];
    this.listParameters.sources = {
      main: "users a",
      children: [
        {
          name: "accounts b",
          on: "a.account_id = b.account_id",
        },
        {
          name: "roles c",
          on: "a.role_id = c.role_id",
        },
        {
          name: "users d",
          on: "a.deleted_by = d.id",
        },
      ],
    };
    this.listParameters.orders = [
      {
        sort: "a.deleted_at",
        dir: "DESC",
      },
      {
        sort: "a.updated_at",
        dir: "DESC",
      },
    ];
  },
  async mounted() {
    this.accounts = await this.getList(
      ["account_id as id, account_name as name"],
      "accounts",
      [],
      [],
      "name"
    );
    this.accounts.forEach(async (d) => {
    this.advertisers[d.id.toString()] = await this.getList(
              ["advertiser_id as id, advertiser_name as name"],
              "advertisers",
              [`account_id = ${d.id.toString()}`],
              [],
              "name"
            );
    });
   
    this.roles = await this.getList(["role_id as id", "name"], "roles", [], [], "name");    
  },
  methods: {
    add() {
      this.action = "add";
      this.model = _.cloneDeep(this.baseModel);
      this.model.password = md5(md5(new Date().getUTCMilliseconds())).substring(1,12);
      this.model.confirm_password = this.model.password;
    },
    edit(item) {
      this.originalModel = _.cloneDeep(item);
      this.model = _.cloneDeep(item);
      this.model.password = "";
      this.model.confirm_password = "";
      this.action = "edit";
    },
    async remove(item) {
      this.originalModel = _.cloneDeep(item);
      this.model = _.cloneDeep(item);
      await this.$refs.container
        .confirm(
          "Confirmation",
          `You are deleting <strong>${this.model.first_name} ${this.model.last_name} (${this.model.email})</strong>. Do you want to continue?`,
          "red"
        )
        .then(async (ans) => {
          if (ans) {
            this.model.deleted_by = this.loggedInUser.id;
            await this.deleteData(`/rest/users/${this.model.id}`, null)
              .then((response) => {
                this.$refs.container.snackBar(
                  `
                The record of ${this.model.first_name} ${this.model.last_name} (${this.model.email}) was successfully deleted.
                `,
                  "red",
                  2000
                );
                this.reset();
              })
              .catch((err) => {
                errors.push(err.data.message);
                this.model = this.baseModel;
                this.originalModel = this.baseModel;
              });
          }
        });
    },
    async save() {
      if (!this.$refs.form.validate()) return false;

      const errors = [];
      const url = "/rest/users";

      if (this.model.password !== this.model.confirm_password) {
        this.$refs.container.snackBar(
          `
            Password and confirm password doesn't match.
            `,
          "red",
          2000
        );
        return false;
      }
      if (this.model.password === "") {
        delete this.model.password;
      }
      delete this.model.role_name;  

      //this.model.account_id = this.loggedInUser.account_id;
      this.model.platform_id = 1; //this.loggedInUser.platform_id;
 
      if (this.action === "add") {
        this.model.created_by = this.loggedInUser.id;
        
        await this.postData(url, this.model)
          .then((response) => {
            this.$refs.container.snackBar(
              `
              Successfully added ${response.data.first_name} ${response.data.last_name} (${response.data.email})
              `,
              "green",
              2000
            );
            this.reset();
          })
          .catch((err) => {
            errors.push(err.data.message);
          });
      }
      if (this.action === "edit") {
        
        const model = _.cloneDeep(this.model);
        if (model.email === this.originalModel.email) {
          delete this.model.email;
        }
        if (this.model.created_by < 1) {
          this.model.created_by = this.loggedInUser.id;
        }

        this.model.updated_by = this.loggedInUser.id;
        await this.putData(`${url}/${this.model.id}`, this.model)
          .then((response) => {
            this.$refs.container.snackBar(
              `
              Successfully updated ${model.first_name} ${model.last_name} (${model.email}).
              `,
              "green",
              2000
            );
            this.reset();
          })
          .catch((err) => {
            errors.push(err.data.message);
          });

        // add update of account id 
        if (model.account_id > 0) {
          const url2 = `/rest/intranet/query`;
          const parameters = _.cloneDeep(this.updateParameters);
          parameters.table = `users`;
          parameters.fields.push({
            field: `account_id`,
            value: model.account_id,
          }); 
          
          parameters.wheres.push({ field: `id`, value: model.id });

          await this.postData(url2, parameters)
          .then((response) => {
            console.log(`account id was updated`)
          })
          .catch((err) => {
            errors.push(err.data.message);
          });
        } 
      }
      if (errors.length > 0) {
        this.$refs.container.snackBar(
          `
              Error updating item: ${errors.join(",")}
              `,
          "red",
          2000
        );
        return false;
      } else {
        this.reset();
      }
    },
    reset(par) {
      if (par) {
        this.parameters = {
          email: "", 
          first_name: "",
          last_name: "",
          role_id: [],
          super_user: [],
          multi_account: [],
          active: [],
          account_id: [],
          user_id: [],
        }
      }
      this.action = "";
      this.model = _.cloneDeep(this.baseModel);
      this.originalModel = _.cloneDeep(this.baseModel);
      
      this.listParameters.wheres = [];
      const wheres = [];      
      if (this.parameters.account_id.length > 0) {
         wheres.push(`a.account_id in (${this.parameters.account_id.join(',')})`)
      }
      if (this.parameters.email != '') {
         wheres.push(`a.email ilike '%${this.parameters.email}%'`)
      }
      if (this.parameters.first_name != '') {
         wheres.push(`a.first_name ilike '%${this.parameters.first_name}%'`)
      }
      if (this.parameters.last_name != '') {
         wheres.push(`a.last_name ilike '%${this.parameters.last_name}%'`)
      }
      if (this.parameters.role_id.length > 0) {
         wheres.push(`a.role_id in (${this.parameters.role_id.join(',')})`)
      }
      if (this.parameters.active.length > 0) {
         wheres.push(`a.active in (${this.parameters.active.join(',')})`)
      }
      if (this.parameters.super_user.length > 0) {
         wheres.push(`a.super_user in (${this.parameters.super_user.join(',')})`)
      }
      if (this.parameters.multi_account.length > 0) {
         wheres.push(`a.multi_account in (${this.parameters.multi_account.join(',')})`)
      }
      if (wheres.length > 0) this.listParameters.wheres = [wheres.join(' OR ')];

      setTimeout(async () => {
        this.$refs.table.refresh();
      }, 200);
    },
    async getAdvertiserByAccount() {
      this.$nextTick(async () => {
        this.model.ui_preferences.account_ids.forEach(async (acct_id) => {       
          if (this.advertisers[acct_id.toString()].length === 0) {
            this.advertisers[acct_id.toString()] = await this.getList(
              ["advertiser_id as id, advertiser_name as name"],
              "advertisers",
              [`account_id = ${acct_id}`],
              [],
              "name"
            );
            console.log(this.advertisers[acct_id.toString()])
          }
        })
        
      });      
    }
  },
};
</script>
<style lang="scss" scoped>
.o-container-content {
  min-height: calc(100vh - 278px);
  max-height: calc(100vh - 278px);
  overflow-y: scroll;
}
</style>
