<template>
  <ManageUser dialog-name="User" />
</template>
<script>
import ManageUser from '@/views/app/manage/User'

export default {
  name: 'UserWrapper',
  components: {
    ManageUser
  }
}
</script>
